@import 'src/css/variable';
.spinner-modal {
  .modal-content {
    border: none;
    background: transparent;
    .spinner {
      height: 40px;
    }
  }
}

@import 'src/css/variable';
.home-page {

  height: 100%;
  display: flex;
  flex-direction: column;

  .welcome-container{
    //top: (60.2/$df-fs)*$rem;
    //position: absolute;
    //left: 50%;
    //-webkit-transform: translateX(-50%);
    //-moz-transform: translateX(-50%);
    //-ms-transform: translateX(-50%);
    //-o-transform: translateX(-50%);
    //transform: translateX(-50%);
    //width: 100%;
    .logo{
      margin-left: auto;
      margin-right: auto;
    }
  }
  .navigation-bar{
    i{
      font-size: ($df-fs);
    }

    .text-icon{
      font-size: ($df-fs)!important;
    }
  }
  .btn-back{
    position: absolute;
    top: 20px;
    left: 20px;
    span{
      margin: 0 5px;
    }
  }
  .logo {
    margin-bottom: 10px;
    height: 80px;
    text-align: center;
    img{
      margin-top:-20px;
      height: 120px;
      max-width:80%;
      margin-left:-20px;
    }
  }
  .title {
    text-align: center;
    font-family: Oswald;
   // font-size: (24/$df-fs)*$rem;
    line-height: 40px;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: black;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    margin: auto;
    text-overflow: ellipsis;
    font-size:$font-size-xlarge
  }
  .main-option{
    background-color: $color-blue;
    margin-top:20px;
    //position: absolute;
    width: 100%;
    bottom: 0;
    .btn-group {
      width: 100%;
      //height: (50/$df-fs)*$rem;
      height:$height-button;
      font-size: $font-size-button;
      background-color: $color-blue;
      display: block;
      margin:auto;
      margin-bottom: 20px;
      color: white;
      text-transform: uppercase;
      border: white 2px solid;
      font-family: Oswald;
      letter-spacing: 0.5px;
      max-width:600px

    }

    @media(min-width: 768px){
      .btn-group {
        height:$height-button-big;
        margin-bottom:30px;
      }
    }
  }


  .main-option-container{
    max-width: 1024px;
    padding: 25px;
    padding-bottom:0;
    margin:auto;
  }

  @media(min-width: 768px){
    .main-option-container {
      padding-bottom: 15px;
      padding-top:40px;
    }
  }

  .footer {
    text-align: center;
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
    bottom: 0;
    .sync-data {
      margin-bottom: 20px;
      padding-left: 30px;
      padding-right:30px;
      max-width: 1024px;;
      margin:auto;

      .needs-sync {
        display: block;
        margin-bottom:6px;
        text-decoration: none;
        color:red;
      }

      .btn-sync {
        width: 100%;
        max-width:220px;
        margin:auto;
        
        //height: (50/$df-fs)*$rem;
        height:$height-button;
        font-size: $font-size-button;
        background-color: $color-blue;
        display: block;
        margin-bottom: 16px;
        margin-top:20px;
        color: white;
        text-transform: uppercase;
        border: white 2px solid;
        font-family: Oswald;
        letter-spacing: 0.5px;
      }

      @media(min-width: 768px){
        .btn-sync {
       
          margin-bottom: 20px;
          margin-top:40px;
         
        }
      }

      i{
       margin-right: 10px;
      }
    
    }
    .last-time {
      font-size:$font-size-default;
      span {
        font-weight: bold;
      }
      opacity: 0.7;
    }
    .version {
      font-size:$font-size-small;
      padding-top:14px;
      padding-bottom:6px;
      opacity: 0.7;
      text-align:center;
      width:100%;

    }
    .reload-link {
      text-decoration: underline;
      cursor:pointer;
      display:inline-block;
      padding-top: 4px;
      opacity:0.7;
      padding-bottom:4px;
      padding-left:9px;
      padding-right:9px;
    }
  }
}

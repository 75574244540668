@import 'src/css/variable';
.warning-modal {
  overflow: hidden !important;
  z-index: 9999;
  padding:20px;
  .modal-dialog {
  
    margin:auto;
  }
  .modal-content {
    position: absolute;
    top: 90px;
    max-height: calc(100vh - 10vh);
    overflow: auto;
    border-radius: 0;
    border-color: transparent;
    * {
      border-color: transparent;
    }
    .modal-header {
      border-radius: 0;
      background-color: $color-blue;
      color: white;
      font-family: Oswald;
      font-size: $font-size-large;;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
    .modal-body {
      color: black;
      font-size: $font-size-default;
      letter-spacing: -0.1px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .input-modal{
      margin:  0 20px 10px;
      position: relative;
      border: 1px solid grey;
      span{
        position: absolute;
        right: 8px;
        top: 13px;
      }
      input{
        text-align: right;
        color: black;
        width: 100%;
        height: 48px;
        padding-right: (21/$df-fs)*$rem;
      }
    }
    .modal-footer {
      .btn {
        font-family: Oswald;
        font-weight: 500;
        font-size: $font-size-default;;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        padding: .375rem .5rem;
        min-width: 100px;
        &.btn-cancel{
          color: black;
        }
        &.btn-ok{
          border-radius: 0;
          color: white;
          background-color: $color-blue;
          height: 40px;
        }
      }
      padding: 8px 20px 20px;
    }
  }
  .error{
    margin-left: 20px;
    color: $color-red;
    font-size: $font-size-default;
  }
  .percent-text{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: (10/$df-fs)*$rem
  }
}

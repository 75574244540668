@font-face {
  font-family: 'icon-nutritech';
  src: url('fonts/icon-nutritech.eot?7t20xz');
  src: url('fonts/icon-nutritech.eot?7t20xz#iefix') format('embedded-opentype'),
  url('fonts/icon-nutritech.ttf?7t20xz') format('truetype'),
  url('fonts/icon-nutritech.woff?7t20xz') format('woff'),
  url('fonts/icon-nutritech.svg?7t20xz#icon-nutritech') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-nutritech' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-black-check:before {
  content: "\e917";
  color: #000;
}
.icon-black--add:before {
  content: "\e900";
  color: #000;
}
.icon-black-arrow-back:before {
  content: "\e901";
  color: #000;
}
.icon-black-checkbox:before {
  content: "\e902";
  color: #000;
}
.icon-black-checkbox-checked:before {
  content: "\e903";
  color: #000;
}
.icon-black-chevron-down:before {
  content: "\e904";
  color: #000;
}
.icon-black-chevron-left:before {
  content: "\e905";
  color: #000;
}
.icon-black-chevron-right:before {
  content: "\e906";
  color: #000;
}
.icon-black-chevron-up:before {
  content: "\e907";
  color: #000;
}
.icon-black-close:before {
  content: "\e908";
  color: #000;
}
.icon-black-delete:before {
  content: "\e909";
  color: #000;
}
.icon-black-dropdown:before {
  content: "\e90a";
  color: #000;
}
.icon-black-edit:before {
  content: "\e90b";
  color: #000;
}
.icon-black-filter:before {
  content: "\e90c";
  color: #000;
}
.icon-black-info:before {
  content: "\e90d";
  color: #000;
}
.icon-black-logout:before {
  content: "\e90e";
  color: #000;
}
.icon-black-no-internet:before {
  content: "\e90f";
  color: #000;
}
.icon-black-pdf:before {
  content: "\e910";
  color: #000;
}
.icon-black-price:before {
  content: "\e911";
  color: #000;
}
.icon-black-save:before {
  content: "\e912";
  color: #000;
}
.icon-black-search:before {
  content: "\e913";
  color: #000;
}
.icon-black-selected:before {
  content: "\e914";
  color: #000;
}
.icon-black-sent:before {
  content: "\e915";
  color: #000;
}
.icon-black-sync:before {
  content: "\e916";
  color: #000;
}

.icon-white-check:before {
  content: "\e917";
  color: #fff;
}
.icon-white-add:before {
  content: "\e900";
  color: #fff;
}
.icon-white-arrow-back:before {
  content: "\e901";
  color: #fff;
}
.icon-white-checkbox:before {
  content: "\e902";
  color: #fff;
}
.icon-white-checkbox-checked:before {
  content: "\e903";
  color: #fff;
}
.icon-white-chevron-down:before {
  content: "\e904";
  color: #fff;
}
.icon-white-chevron-left:before {
  content: "\e905";
  color: #fff;
}
.icon-white-chevron-right:before {
  content: "\e906";
  color: #fff;
}
.icon-white-chevron-up:before {
  content: "\e907";
  color: #fff;
}
.icon-white-close:before {
  content: "\e908";
  color: #fff;
}
.icon-white-delete:before {
  content: "\e909";
  color: #fff;
}
.icon-white-dropdown:before {
  content: "\e90a";
  color: #fff;
}
.icon-white-edit:before {
  content: "\e90b";
  color: #fff;
}
.icon-white-filter:before {
  content: "\e90c";
  color: #fff;
}
.icon-white-info:before {
  content: "\e90d";
  color: #fff;
}
.icon-white-logout:before {
  content: "\e90e";
  color: #fff;
}
.icon-white-no-internet:before {
  content: "\e90f";
  color: #fff;
}
.icon-white-pdf:before {
  content: "\e910";
  color: #fff;
}
.icon-white-price:before {
  content: "\e911";
  color: #fff;
}
.icon-white-save:before {
  content: "\e912";
  color: #fff;
}
.icon-white-search:before {
  content: "\e913";
  color: #fff;
}
.icon-white-selected:before {
  content: "\e914";
  color: #fff;
}
.icon-white-sent:before {
  content: "\e915";
  color: #fff;
}
.icon-white-sync:before {
  content: "\e916";
  color: #fff;
}
.icon-blue-close:before {
  content: "\e908";
  color: #008eaa;
}
/*--------------------Custom Icon----------------------*/

.icon-check:before {
  content: "\e917";
}
.icon-add:before {
  content: "\e900";
}
.icon-arrow-back:before {
  content: "\e901";
}
.icon-checkbox:before {
  content: "\e902";
}
.icon-checkbox-checked:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-delete:before {
  content: "\e909";
}
.icon-dropdown:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-filter:before {
  content: "\e90c";
}
.icon-info:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-no-internet:before {
  content: "\e90f";
}
.icon-pdf:before {
  content: "\e910";
}
.icon-price:before {
  content: "\e911";
}
.icon-save:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-selected:before {
  content: "\e914";
}
.icon-sent:before {
  content: "\e915";
}
.icon-sync:before {
  content: "\e916";
}


// Colors
$color-green: rgb(0, 74, 55);
$color-darkgreen: rgb(19, 56, 34);
$color-amber: rgb(211,68,55);
$color-charcoal: #000 ;
$color-lightgrey: rgb(212, 212, 212);
$color-lightestgrey: rgb(233, 233, 233);
$color-ryegrass: rgb(140, 183, 98);
$color-grey: #919195;
$color-maize: rgb(160, 115, 104);
$color-white: rgb(255, 255, 255);
$color-blue: #008eaa;
$color-red: rgb(211,68,55);
$padding: 20px;
$selectProductBarMinHeight: 60px;
$selectProductBarMaxHeight: 70px;
$filterHeaderHeight: 30px;

// Fonts
$df-fs: 14;
$rem: 1rem;
$vw: 1vw;

$font-size-button: 16px;
$height-button: 48px;
$height-button-big: 68px;

$font-size-default: 14px;
$font-size-large: 18px;
$font-size-xlarge: 22px;
$font-size-small: 12.5px;

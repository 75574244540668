@import 'variable';
@import 'font';
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

body{
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

html {
  font-size: $font-size-default;
  @media(min-width: 360px) and (max-height: 568px){
    font-size:$font-size-default
  }

  @media(min-width: 768px){
    font-size:$font-size-default
  }
}

/*-----------------------------FONT CONFIG START----------------------------------*/
h1 {
  font-size: $font-size-xlarge;
  letter-spacing: -0.3px;
  font-weight: 900;
  color: $color-green;
}

h2 {
  font-size: 20px !important;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: $color-white;
}
h3 {
  font-size: $font-size-large !important;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: $color-white;
}
h4 {
  font-size: $font-size-large;
  color: $color-darkgreen;
  font-weight: 700;
}
button{
  &:active{
    opacity: 0.7;
  }
}
.intro {
  font-size: $font-size-large;
  font-weight: 200;
  line-height: 24px;
  color: $color-white;
}

.sub-nav {
  &-link {
    font-size: 10px;
    color: $color-amber;
  }
}
.caption {
  &-bold {
    font-size: 10px;
    font-weight: 700;
    color: $color-grey;
  }
  &-regular {
    font-size: 10px;
    color: $color-grey;
  }
}
.green {
  color: $color-green;
}
.orange {
  color: $color-amber;
}
/*-----------------------------FONT CONFIG END----------------------------------*/

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.noscroll {
  overflow: hidden !important;
}

.text-link{
  &:active{
    opacity: 0.7;
  }
}


/* added when porting after newer bootstrap version missing padding, todo look into something tidier */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10,.col-11, .col-12 {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}
.pr-0, .px-0 {
  padding-right: 0 !important;
}

.icon-item, .edit-icon, .text-link{
  cursor: pointer;
}

.environment {
  position:absolute; 
  top: 4px;
  right: 10px; 
  color:orange;
  text-transform: lowercase;
  font-size: $font-size-small;
}

@import 'src/css/variable';
.navigation-bar {
  padding:  15px 20px 0;
  .btn {
    color: $color-blue;
    display: flex;
    align-items: center;
    //justify-content: center;
    div {
      position: relative;
      font-size: $font-size-large;;
    }
    &.btn-left{
      padding-left:  0;
      span{
        font-size: $font-size-default;
        padding: 5px;
        color: $color-blue;
        letter-spacing: -0.1px;
        text-decoration: underline;
      }
    }
  }
  .center {
    padding: 0;
    .center-text{
      color: black;
      text-transform: uppercase;
      font-size: $font-size-default;
      font-weight: bold;
      letter-spacing: 0.1px;
      text-align: center;
    }
  }
  i {
    font-size: 15px;
  }
  .info {
    color: $color-blue;
    vertical-align: middle;
    font-size: $font-size-default;
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
    display: flex;
    .detail{
      padding: 0 5px;
      font-weight: 100;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header{
    padding: 0;
    .title{
      font-family: Oswald;
      font-size: $font-size-xlarge;
      line-height: 1.29;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }
}

